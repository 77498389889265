import { useState } from 'react';
import { CropperProps } from 'react-easy-crop';
import { Upload, UploadFile, UploadProps, message } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { InboxOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';
import { getPresignUrl } from 'hooks';
import { OssResponse } from 'interfaces';

export const AliyunOSSUploadCroppable = ({
  aspect,
  cropperProps,
  purpose,
  accept = '*',
  description,
  onChange,
}: {
  aspect?: number;
  cropperProps?: Partial<CropperProps>;
  purpose: 'PersonalBrandedSite' | 'PlaylistThumbnail' | 'VideoSubtitle' | 'VideoThumbnail' | 'Banner';
  accept?: string;
  description?: string;
  onChange: (OSSData: OssResponse) => void;
}) => {
  const t = useTranslate();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [OSSData, setOSSData] = useState<OssResponse>();

  const handleRequest = async (options: any) => {
    const file: any = options.file;

    try {
      const response = await axios.put(options.action, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      onChange(OSSData as OssResponse);
      message.success(`${file?.name} file uploaded successfully.`);
      options.onSuccess(response?.data);
    } catch (error) {
      message.error(`${file?.name} file upload failed.`);
      options.onError(error);
    }
  };

  const uploadProps: UploadProps = {
    accept,
    name: OSSData?.path.split('/').pop(),
    action: OSSData?.signedUrl,
    maxCount: 1,
  };

  return (
    <ImgCrop
      modalTitle={t('crop.editImage')}
      modalCancel={t('buttons.cancel')}
      modalOk={t('buttons.ok')}
      aspect={aspect}
      rotate
      quality={1}
      cropperProps={{
        ...cropperProps,
      }}
    >
      <Upload.Dragger
        {...uploadProps}
        onRemove={(file: UploadFile) => {
          setFileList(fileList.filter((item) => item.uid !== file.uid));
        }}
        onChange={({ file, fileList }) => {
          setFileList([...fileList]);
        }}
        customRequest={handleRequest}
        beforeUpload={async (file: File) => {
          await getPresignUrl({
            params: {
              fileName: file.name,
              purpose: 'PersonalBrandedSite',
            },
            onSuccess: (response: OssResponse) => {
              setOSSData(response);
              setFileList([
                {
                  uid: '-1',
                  name: file.name,
                  status: 'done',
                  url: response?.url,
                },
              ]);
            },
            onError: (error) => {
              message.error('Error: ' + error);
            },
          });

          return file;
        }}
        fileList={fileList}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('upload.placeholder')}</p>
        {description && <p className="ant-upload-hint" dangerouslySetInnerHTML={{ __html: description }}></p>}
      </Upload.Dragger>
    </ImgCrop>
  );
};
