import React from 'react';
import { Breadcrumb, Button, Col, DateField, ExportButton, Icons, List, Row, Space, Table } from '@pankod/refine-antd';
import { IResourceComponentsProps, useTranslate } from '@pankod/refine-core';
import { ChannelDetailDialog, ChannelFilter } from 'components/channel';
import { useExportChannelReport } from './hooks/use-export-channel-report';
import { useListChannels } from './hooks';
import { IChannel } from 'interfaces';
import moment from 'moment';

export const ChannelManagementList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const [viewRecordId, setViewRecordId] = React.useState<string | null>(null);
  const showRecordModal = (record: any) => setViewRecordId(record.id);
  const closeRecordModal = () => setViewRecordId(null);

  const { tableProps, searchFormProps, tableQueryResult, filters } = useListChannels();

  const channel = searchFormProps.form.getFieldValue('channel');
  const setupCompleteDates = searchFormProps.form.getFieldValue('setupCompleteDates') || [];

  const { triggerExport, isLoading } = useExportChannelReport({
    filters,
    headers: [
      'Channel Report',
      `Export Date: ${moment().format('DD-MMM-YYYY')}`,
      `Channel Name: ${channel?.name || 'ALL'}`,
      `Selected Period: ${setupCompleteDates?.map((date) => date.format('DD-MMM-YYYY')).join(' - ') || 'ALL'}`,
    ],
  });

  const Actions: React.FC = () => (
    <Space>
      <ExportButton
        loading={isLoading}
        onClick={() => {
          triggerExport();
        }}
      />
    </Space>
  );

  return (
    <Row gutter={[16, 16]}>
      {viewRecordId && <ChannelDetailDialog channelId={viewRecordId} close={closeRecordModal} />}

      <Col xl={24} lg={24} xs={24}>
        <ChannelFilter formProps={searchFormProps} tableQueryResult={tableQueryResult} filters={filters || []} />
      </Col>

      <Col xl={24} xs={24}>
        <List
          pageHeaderProps={{
            title: t('channelsManagement.channels'),
            breadcrumb: <Breadcrumb hideIcons />,
            extra: <Actions />,
          }}
        >
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="name"
              title={t('channelsManagement.fields.channelName')}
              render={(value) => value || '-'}
            />
            <Table.Column
              dataIndex="approvedAt"
              title={t('channelsManagement.fields.joinedDate')}
              render={(value) => (value ? <DateField value={value} format="lll" /> : 'N/A')}
            />
            <Table.Column
              title={t('channelsManagement.fields.setupChannelCompletely')}
              dataIndex="setupCompletedAt"
              key="setupCompletedAt"
              render={(value) => (value ? 'Yes' : 'N/A')}
            />
            <Table.Column<IChannel>
              title={t('channelsManagement.fields.monetizationTools')}
              render={(_, record) => (record?.isGiftEnabled || record?.isSubscriptionEnabled ? 'Yes' : 'No')}
            />
            <Table.Column
              dataIndex="totalReleasedVideoCount"
              title={t('channelsManagement.fields.totalReleasedVideos')}
            />
            <Table.Column<IChannel>
              width="5%"
              key="actions"
              title={t('tables.actions')}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <Button size="small" icon={<Icons.EyeOutlined />} onClick={() => showRecordModal(record)} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
